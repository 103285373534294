import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.evaluationCriterias,
  maxOrderNoPath: ApiExtRoutes.evaluationCriteriaMaxOrderNo,
})

import {
  COUNT_4_GPA_SYMBOLS,
  IS_OPTIONAL_SYMBOLS,
  PROGRAMME_STATUSES,
  PROGRAMME_SUBJECT_TYPE,
  STATUS_SYMBOLS,
  REPLACE_THESIS_SUBJECT_SYMBOLS, REPLACE_THESIS_SUBJECT_TYPES,
  CRITERIA_REQUIRES,
} from '@/const/status'

import {
  CRITERIA_TYPES,
} from '@/const/type'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: PROGRAMME_STATUSES,
    statusSymbols: STATUS_SYMBOLS,
    countedForGpaTypes: STATUS_SYMBOLS,
    isOptionalTypes: IS_OPTIONAL_SYMBOLS,
    criteriaTypes: CRITERIA_TYPES,
    criteriaRequires: CRITERIA_REQUIRES,
    replaceThesisSubjectTypes: REPLACE_THESIS_SUBJECT_TYPES,
    resourceName: { fullName: 'Học phần CTĐT', shortName: 'HP' },
    maxOrderNo: 0,
    subjects: [],
    dataResourecesExport: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    statusSymbols: state => state.statusSymbols,
    criteriaTypes: state => state.criteriaTypes,
    criteriaRequires: state => state.criteriaRequires,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    subjects: state => state.subjects,
    countedForGpaTypes: state => state.countedForGpaTypes,
    isOptionalTypes: state => state.isOptionalTypes,
    replaceThesisSubjectTypes: state => state.replaceThesisSubjectTypes,
    dataResourecesExport: state => state.dataResourecesExport,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_SUBJECTS: (state, records) => {
      state.subjects = records
    },
    SET_DATA_SOURCES_EXPORT: (state, data) => { state.dataResourecesExport = data },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createData(commit, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.maxOrderNoPath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataResourcesExport({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/exportEvaluationCriteria`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_SOURCES_EXPORT', data.data)
        }
      } catch (e) {
        handleCatch(e)  
      }
    },

    async importCriteria({ commit }, params) {
      try {
          const response = await axios.post(`${baseAttr.basePath}/importCriteria`, { params })
          const { data } = response
          return data;
      } catch (e) {
          handleCatch(e)
      }
    },
  },
}
