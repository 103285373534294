import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/student-transfers',
    name: 'studentTransfer',
    component: () => import('@/views/student-transfer/StudentTransfer.vue'),
    meta: {
      pageTitle: 'Xét duyệt học phần sinh viên học liên thông',
      breadcrumb: [
        {
          text: 'Quản lý đào tạo',
          active: false,
        },
        {
          text: 'Xét duyệt học phần liên thông',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENT_TRANSFER,
    },
  },
  {
    path: '/student-transfers/programme/{id}',
    name: 'studentTransferByProgramme',
    component: () => import('@/views/student-transfer/StudentTransfer.vue'),
    meta: {
      pageTitle: 'Chi tiết học phần sinh viên được xét duyệt liên thông',
      breadcrumb: [
        {
          text: 'Quản lý sinh viên',
          active: false,
        },
        {
          text: 'Xét duyệt học phần liên thông',
          active: true,
        },
        {
          text: 'Chi tiết',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENT_TRANSFER,
    },
  },
]
