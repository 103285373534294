import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    accounts: [],
    accountsNoPassword: [],
  },
  getters: {
    accounts: state => state.accounts,
    accountsNoPassword: state => state.accountsNoPassword,
  },
  mutations: {
    SET_ACCOUNTS: (state, data) => { state.accounts = data },
    SET_ACCOUNTS_NO_PASSWORD: (state, data) => { state.accountsNoPassword = data },
  },
  actions: {
    async getAccounts({ commit }, params) {
      try {
        const response = await axios.get('uni/accounts/getAccountsHaveScoreLockPassword', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ACCOUNTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAccountsNoPassword({ commit }, params) {
      try {
        const response = await axios.get('uni/accounts/getAccountsHaveScoreLockPassword', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_ACCOUNTS_NO_PASSWORD', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createScoreLockPassword({ commit }, params) {
      try {
        const response = await axios.post('uni/accounts/createScoreLockPassword', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteScoreLockPassword({ commit }, id) {
      try {
        const response = await axios.delete(`uni/accounts/${id}/scoreLockPassword`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async changeScoreLockPassword({ commit }, params) {
      try {
        const response = await axios.put(`uni/accounts/${params.id}/scoreLockPassword`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async resetScoreLockPassword({ commit }, id) {
      try {
        const response = await axios.put(`uni/accounts/${id}/resetScoreLockPassword`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async changeRecoverDataPassword({ commit }, params) {
      try {
        const response = await axios.put(`uni/accounts/${params.id}/recoverDataPassword`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async resetRecoverDataPassword({ commit }, id) {
      try {
        const response = await axios.put(`uni/accounts/${id}/resetRecoverDataPassword`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
