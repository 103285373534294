import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    admissionForms: [],
    totalRows: 0,
    admissionFormByAdmissionRoundId: [],
    codeAdmissionForm: [],
    maxOrderNo: 0,
  },
  getters: {
    admissionForms: state => state.admissionForms,
    totalRows: state => state.totalRows,
    admissionFormByAdmissionRoundId: state => state.admissionFormByAdmissionRoundId,
    codeAdmissionForm: state => state.codeAdmissionForm,
    maxOrderNo: state => state.maxOrderNo,
  },
  mutations: {
    SET_ADMISSION_FORMS: (state, { total, records }) => {
      state.totalRows = total
      state.admissionForms = records
    },
    SET_ADMISSION_FORM_BY_ADMISSION_ROUND_ID: (state, data) => { state.admissionFormByAdmissionRoundId = data },
    SET_CODE_BY_ADMISSION_ID: (state, data) => { state.codeAdmissionForm = data },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => { state.maxOrderNo = maxOrderNo },
  },
  actions: {
    async readAdmissionForms({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_form', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_ADMISSION_FORMS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readAdmissionFormByAdmissionRoundId({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_form/getAdmissionFormByAdmissionRoundId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.records.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_ADMISSION_FORM_BY_ADMISSION_ROUND_ID', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createAdmissionForms({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_form', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateAdmissionForms({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_form/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteAdmissionForms({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_form/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCodeByAdmissionForm(commit) {
      try {
        const response = await axios.get('admissions/admission_form/getAdmissionFormById', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CODE_BY_ADMISSION_ID', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_form/max/order-no', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
