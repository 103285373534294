import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/credential',
    name: 'credential',
    component: () => import('@/views/credential/Credential.vue'),
    meta: {
      pageTitle: 'Danh sách các văn bằng/chứng chỉ',
      breadcrumb: [
        {
          text: 'Quản lý văn bằng chứng chỉ',
          active: false,
        },
        {
          text: 'Quản lý các văn bằng/chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CREDENTIAL,
    },
  },
  {
    path: '/credentialType',
    name: 'credentialType',
    component: () => import('@/views/credential-type/CredentialType.vue'),
    meta: {
      pageTitle: 'Danh sách các kiểu văn bằng/chứng chỉ',
      breadcrumb: [
        {
          text: 'Quản lý văn bằng chứng chỉ',
          active: false,
        },
        {
          text: 'Quản lý các kiểu văn bằng/chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CREDENTIAL_TYPE,
    },
  },
  {
    path: '/certificateBlank',
    name: 'certificateBlank',
    component: () => import('@/views/certificate-blank/CertificateBlank.vue'),
    meta: {
      pageTitle: 'Quản lý kho phôi văn bằng/chứng chỉ',
      breadcrumb: [
        {
          text: 'Quản lý văn bằng/chứng chỉ',
          active: false,
        },
        {
          text: 'Quản lý kho phôi văn bằng/chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CERTIFICATE_BLANK,
    },
  },
  {
    path: '/certificateProcessType',
    name: 'certificateProcessType',
    component: () => import('@/views/certificate-process-types/CertificateProcessType.vue'),
    meta: {
      pageTitle: 'Quản lý các loại yêu cầu cho văn bằng chứng chỉ',
      breadcrumb: [
        {
          text: 'Quản lý văn bằng/chứng chỉ',
          active: false,
        },
        {
          text: 'Quản lý các loại yêu cầu cho văn bằng/chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CERTIFICATE_PROCESS_TYPE,
    },
  },
  {
    path: '/createCredential',
    name: 'createCredential',
    component: () => import('@/views/create-credential/CreateCredential.vue'),
    meta: {
      pageTitle: 'Quản lý cấp văn bằng, chứng chỉ',
      breadcrumb: [
        {
          text: 'Quản lý văn bằng/chứng chỉ',
          active: false,
        },
        {
          text: 'Cấp văn bằng, chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CREATE_CREDENTIAL,
    },
  },
  {
    path: '/credentialStorage',
    name: 'credentialStorage',
    component: () => import('@/views/credential-storage/CredentialStorage.vue'),
    meta: {
      pageTitle: 'Quản lý kho văn bằng, chứng chỉ',
      breadcrumb: [
        {
          text: 'Quản lý văn bằng/chứng chỉ',
          active: false,
        },
        {
          text: 'Kho văn bằng, chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CREDENTIAL_STORAGE,
    },
  },
  {
    path: '/credentialProcessRequest',
    name: 'credentialProcessRequest',
    component: () => import('@/views/credential-process-request/CredentialProcessRequest.vue'),
    meta: {
      pageTitle: 'Quản lý yêu cầu xử lý văn bằng, chứng chỉ',
      breadcrumb: [
        {
          text: 'Quản lý văn bằng/chứng chỉ',
          active: false,
        },
        {
          text: 'Quản lý yêu cầu xử lý văn bằng, chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CREDENTIAL_PROCESS_REQUEST,
    },
  },
  {
    path: '/certificateImplements',
    name: 'certificateImplements',
    component: () => import('@/views/cerificate-implement/CertificateImplement.vue'),
    meta: {
      pageTitle: 'Quản lý khung thời gian triển khai văn bằng, chứng chỉ',
      breadcrumb: [
        {
          text: 'Quản lý văn bằng/chứng chỉ',
          active: false,
        },
        {
          text: 'Quản lý khung thời gian triển khai văn bằng, chứng chỉ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.CERTIFICATE_IMPLEMENT,
    },
  },
]
